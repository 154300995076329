import React from 'react'
import tw, { css, styled } from 'twin.macro'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { CalendarIcon, UserCircleIcon } from '@heroicons/react/24/outline'
import { graphql } from 'gatsby'
import SeoPlugin from 'gatsby-plugin-wpgraphql-seo'
import Wysiwyg from '../components/Wysiwyg'
import Seo from '../components/Seo'

const PostContainer = styled.div`
  ${tw`relative w-full mx-auto px-4 max-w-3xl phone:px-0`}
  a {
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }
`

function PostTemplate({ data: { wpPost: data } }) {
  // console.log('Post page', data)

  const articleImage = getImage(data.featuredImage?.node.gatsbyImage)
  console.log('POST DATA', data)
  return (
    <>
      {/* <Seo title={data?.seo?.title} description={data?.seo?.metaDesc} /> */}
      <SeoPlugin post={data} title={data?.seo?.title} description={data?.seo?.metaDesc} />
      <PostContainer as="article" tw="py-10 phone:px-5 tablet:py-5">
        <h1 tw="text-center font-black text-xl md:text-2xl">{data.title}</h1>
        {articleImage && (
          <GatsbyImage
            tw="rounded-3xl mb-5 max-h-[350px] text-center flex ml-auto mr-auto"
            css={css`
              aspect-ratio: 16/9;
            `}
            imgStyle={{ borderRadius: `1.5rem` }}
            image={articleImage}
            alt={data.featuredImage?.node.altText}
          />
        )}
        <div tw="mx-auto mb-10 flex justify-around items-center text-xs font-bold">
          {data.date && (
            <span tw="flex justify-center items-center text-black">
              <CalendarIcon tw="w-5 text-purple mr-1" />
              {data.date}
            </span>
          )}
          {data.categories.nodes[0]?.name && (
            <span tw="rounded-full bg-purple-400 bg-opacity-90 w-min py-2 px-3 text-center text-xs font-bold whitespace-nowrap text-white">
              {data.categories.nodes[0]?.name}
            </span>
          )}
        </div>
        <Wysiwyg content={data.content} />
      </PostContainer>
    </>
  )
}

export default PostTemplate

export const query = graphql`
  query PostQuery($id: String!) {
    wpPost(id: { eq: $id }) {
      title
      content
      date(locale: "America/Toronto", formatString: "MMMM Do, YYYY")
      author {
        node {
          name
          avatar {
            url
          }
        }
      }
      seo {
        readingTime
        breadcrumbs {
          text
          url
        }
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      categories {
        nodes {
          name
          uri
          meta {
            image {
              id
              altText
              gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 1400)
            }
          }
        }
      }
      featuredImage {
        node {
          id
          altText
          gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 1400)
        }
      }
    }
  }
`
